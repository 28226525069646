import {server} from "@/server/http-common";

export class TagSetHttpService {
    private server = server;
    private defaultApiPrefix = "/api/v1/sets"

    public getAllSets(): Promise<any> {
        return this.server.get(this.defaultApiPrefix + "/all")
    }

    public getTagSets(): Promise<any> {
        return this.server.get(this.defaultApiPrefix)
    }
}